const english = {
    prawahana: "Prawahana",
    whtWeDo: "What we do",
    footer: "This is Footer",
    heroTxtSmall: "The Biggest Tech Brand",
    heroTxtLarge: "In Supply Chain Transportation of Sri Lanka",
    heroButton: "Coming Soon",
    comeSoon: "Coming soon on",
    findUs: "Find us on",
    tagLine: "Online Supply Chain Transportation Marketplace",
    stayTuned: "Stay Tuned with us!",
    enterEmail: "Enter Your Email Here",
    subscribe: "Subscribe",
    whoWeAre: "Who We Are?",
    whoWeAreDesc: "The “Prawahana” brand is a revolutionary technology model tailored to the needs of the Sri Lankan community. This is a very good technology-driven supply chain project that offers many advantages and improvements to the income stream for the farmers, the wholesale trading community, and the drivers. This \"Prawahana\" technical model, designed according to the Sen Transport brand, which is currently operating successfully in Australia, will come to you with many services. The main objective of our team is to make the Sri Lanka of tomorrow, today, the \"Prawahana\" brand that expands the income avenues for every community in the transport supply sector which ultimately makes an economically strong Sri Lanka.",
    appriBrand: "For those who appreciate our Sri Lankan brands",
    driver: "Driver",
    shipper: "Shipper",
    howWeWorkk: "",
    workDriverTitle: "How to find and handle hires in a very short time with \"Prawahana\"?",
    workShipperTitle: "How to transport all your supplies in a very short time with \"Prawahana\"?",
    driverStep1: "Download and Open the Prawahana App",
    driverStep2: "Register and Set Up Your Account",
    driverStep3: "Switch On Your Online Button and Search for Jobs",
    driverStep4: "Select a Job and Enter Your Bidding Value",
    driverStep5: "Negotiate and Accept the Final Bidding Value",
    driverStep6: "Start the Job and Update Job Status",
    driverStep7: "Complete the Job and Withdraw Money",
    shipperStep1: "Download and Open the Prawahana App",
    shipperStep2: "Register and Set Up Your Account",
    shipperStep3: "Post a Job and Receive Bids",
    shipperStep4: "Negotiate and Offer the Job",
    shipperStep5: "Make Payment and Track Job Status",
    shipperStep6: "View Pick-Up and Delivery Proofs",
    shipperStep7: "Receive Completion Notification and Update Satisfaction Form",
    comment1: "Prawahana is a very useful brand. The transportation team helped me a lot to improve my business. The technical service and customer service of those guys are very special.",
    comment2: "We must appreciate the contribution of the Prawahana to take the vegetables from Nuwara Eliya to Colombo. I used to sell vegetables only once a week, but now I have made a way to send them three times a week, so my income has increased.",
    comment3: "I went hires empty for so long when I was returning back from Manning Market in Colombo to Nuwara Eliya. But since the day hire started running with Prawahana, I was able to find hires even when I returned. My income increased.",
    name1: "Anuranga Senanayake",
    name2: "Suchith Somaratne",
    name3: "Ruwan Ratnayake",
    post1: "Wholesale - Kiribathgoda",
    post2: "Vegetable Farmers Association - Nuwara Eliya",
    post3: "Drivers’ Association - Nuwara Eliya",
    whtTheySay: "What they say about us",
    yourThoughts: "Your thoughts about us",
}

export default english;