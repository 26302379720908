import './App.css';
import Header from "./components/Header";
import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';
import english from "./languages/English";
import tamil from "./languages/Tamil";
import sinhala from "./languages/Sinhala";
import Hero from "./components/Hero";
import Footer from "./components/Footer";
import HowWeWork from "./components/HowWeWork";
import WhoWeAre from "./components/whoWeAre";
import ImageGallery from "./components/imageGallery";
import Testimonials from "./components/Testimonials";
import {useState} from "react";

const resources = {
    en: {translation: english},
    sin: {translation: sinhala},
    tml: {translation: tamil},
};

i18n.use(initReactI18next).init({
    resources,
    lng: 'en', // default language
    interpolation: {
        escapeValue: false,
    },
});

function App() {
    const [lang, setLang] = useState('en');

    const onLangChange = (lang) => {
        setLang(lang);
    }

    return (
        <div className={`flex flex-col ${lang === "sin" ? 'font-sinhala' : 'font-english'}`}>
            <Header onLangChange={onLangChange}/>
            <Hero/>
            <HowWeWork/>
            <ImageGallery/>
            <WhoWeAre/>
            <Testimonials/>
            <Footer/>
        </div>
    );
}

export default App;
