import {useState} from "react";
import logo from "../assets/logo.svg"
import LangDropDown from "./LangDropDown";
import {useTranslation} from "react-i18next";
import Hamburger from "hamburger-react";

const Header = (props) => {
    const [isOpen, setIsOpen] = useState(false);
    const {i18n} = useTranslation();
    const [lang, setLang] = useState();
    const changeLanguageHandler = (language) => {
        i18n.changeLanguage(language); // Change the language dynamically
        setLang(language);
        props.onLangChange(language);
    };

    return (
        <>
            <nav className="nav-bar shadow hidden-on-mobile top-0 hidden-on-mobile" style={{zIndex:30}}>
                <div className="flex flex-row items-center justify-between mx-auto p-4" style={{height: '70px', zIndex: 30}}>
                    <div className="flex-grow-0 w-1/4 mx-10">
                        <img src={logo} className="nav-logo object-contain w-7/12 my-5" alt="logo"/>
                    </div>
                    <div className="mx-10 z-40">
                        <select className="bg-gray-100 border border-black-700 rounded py-1 px-4" value={lang}
                                onChange={e => changeLanguageHandler(e.target.value)}>
                            <option value="en">English</option>
                            <option value="sin">සිංහල</option>
                            <option value="tml">தமிழ்</option>
                        </select>
                    </div>
                </div>
            </nav>
            <nav className="nav-bar shadow flex flex-row items-center justify-between top-0  hidden-on-desktop"
                 style={{height: '70px', zIndex:30}}>
                    <div className="flex-grow-0 w-1/3 mx-10">
                        <img src={logo} className="w-full object-cover" alt="logo"/>
                    </div>
                    <div>
                        <Hamburger
                            toggled={isOpen}
                            toggle={setIsOpen}
                            color="black"
                            size="25"
                        />
                    </div>
                {isOpen && (
                    <div className="absolute top-0 left-0 w-full h-auto bg-white z-20 flex flex-col items-center ease-in-out" style={{marginTop: '70px'}}>
                        <div className="flex flex-row justify-between my-10">
                            <button className={`py-2 px-4 mx-3 rounded-lg font-english ${lang === 'en' ? 'bg-prw-blue text-white' : 'bg-gray-200 text-black'}`} onClick={()=> changeLanguageHandler('en')}>English</button>
                            <button className={`py-2 px-4 mx-3 rounded-lg font-english ${lang === 'sin' ? 'bg-prw-blue text-white' : 'bg-gray-200 text-black'}`} onClick={()=> changeLanguageHandler('sin')}>සිංහල</button>
                            <button className={`py-2 px-4 mx-3 rounded-lg font-english ${lang === 'tml' ? 'bg-prw-blue text-white' : 'bg-gray-200 text-black'}`} onClick={()=> changeLanguageHandler('tml')}>தமிழ்</button>
                        </div>
                    </div>
                )}
            </nav>
        </>
    );
}

export default Header;