const tamil = {
    prawahana: "Prawahana",
    whtWeDo: "What we do",
    footer: "This is Footer Tamil",
    heroTxtSmall: "மிகப்பெரிய தொழில்நுட்ப பிராண்ட்",
    heroTxtLarge: "இலங்கையின் விநியோக சங்கிலி போக்குவரத்தில்",
    heroButton: "விரைவில்",
    comeSoon: "விரைவில் வரும்",
    findUs: "எங்களை",
    tagLine: "ஆன்லைன் விநியோக சங்கிலி போக்குவரத்து சந்தை",
    stayTuned: "எங்களுடன் இணைந்திருங்கள்!",
    enterEmail: "உங்கள் மின்னஞ்சலை இங்கே உள்ளிடவும்",
    subscribe: "குழுசேர்",
    whoWeAre: "நாங்கள் யார்?",
    whoWeAreDesc: "\"பிரவாஹன\" வர்த்தக நாமம் இலங்கை சமூகத்தின் தேவைகளுக்கு ஏற்ப உருவாக்கப்பட்ட ஒரு புரட்சிகரமான தொழில்நுட்ப மாதிரியாகும். விவசாயிகள், மொத்த வர்த்தக சமூகம் மற்றும் ஓட்டுநர்களுக்கு வருமான ஓட்டத்தில் பல நன்மைகள் மற்றும் மேம்பாடுகளை வழங்கும் ஒரு சிறந்த தொழில்நுட்பம் சார்ந்த விநியோகச் சங்கிலித் திட்டமாகும். தற்போது ஆஸ்திரேலியாவில் வெற்றிகரமாக இயங்கி வரும் சென் டிரான்ஸ்போர்ட் பிராண்டின் படி வடிவமைக்கப்பட்ட இந்த \"பிரவாஹானா\" தொழில்நுட்ப மாடல் பல சேவைகளுடன் உங்களிடம் வரும். எமது குழுவின் பிரதான நோக்கம், நாளைய இலங்கையை இன்றைய, \"பிரவாஹனா\" வர்த்தக நாமமாக மாற்றுவது ஆகும், இது போக்குவரத்து விநியோகத் துறையில் ஒவ்வொரு சமூகத்திற்கும் வருமான வழிகளை விரிவுபடுத்துகிறது, இது இறுதியில் பொருளாதார ரீதியாக வலுவான இலங்கையை உருவாக்குகிறது.",
    appriBrand: "எமது இலங்கை வர்த்தக நாமங்களை பாராட்டுபவர்களுக்கு",
    driver: "இயக்கி",
    shipper: "ஏற்றுமதி செய்பவர்",
    workDriverTitle: "\"பிரவாஹானா\" மூலம் மிகக் குறுகிய காலத்தில் பணியமர்த்துபவர்களைக் கண்டுபிடித்து கையாள்வது எப்படி?",
    workShipperTitle: "\"பிரவாஹானா\" மூலம் உங்கள் அனைத்து பொருட்களையும் மிகக் குறுகிய காலத்தில் கொண்டு செல்வது எப்படி?",
    driverStep1: "பிரவாஹானா செயலியை பதிவிறக்கம் செய்து திறக்கவும்",
    driverStep2: "பதிவு செய்து உங்கள் கணக்கை அமைக்கவும்",
    driverStep3: "உங்கள் ஆன்லைன் பட்டனை இயக்கி வேலைகளைத் தேடவும்s",
    driverStep4: "ஒரு வேலையைத் தேர்ந்தெடுத்து உங்கள் ஏல மதிப்பை உள்ளிடவும்",
    driverStep5: "பேச்சுவார்த்தை நடத்தி இறுதி ஏல மதிப்பை ஏற்கவும்",
    driverStep6: "வேலையைத் தொடங்கி, வேலை நிலையைப் புதுப்பிக்கவும்",
    driverStep7: "வேலையை முடித்து பணத்தை எடுக்கவும்",
    shipperStep1: "பிரவாஹானா செயலியை பதிவிறக்கம் செய்து திறக்கவும்",
    shipperStep2: "பதிவு செய்து உங்கள் கணக்கை அமைக்கவும்",
    shipperStep3: "ஒரு வேலையை இடுகையிடவும் மற்றும் ஏலங்களைப் பெறவும்",
    shipperStep4: "பேச்சுவார்த்தை நடத்தி வேலையை வழங்குங்கள்",
    shipperStep5: "பணம் செலுத்தி வேலை நிலையை கண்காணிக்கவும்",
    shipperStep6: "பிக்-அப் மற்றும் டெலிவரி சான்றுகளைப் பார்க்கவும்",
    shipperStep7: "நிறைவு அறிவிப்பைப் பெறவும் மற்றும் திருப்திப் படிவத்தைப் புதுப்பிக்கவும்m",
    comment1: "பிரவாஹானா மிகவும் பயனுள்ள பிராண்ட். எனது வணிகத்தை மேம்படுத்த போக்குவரத்துக் குழு எனக்கு நிறைய உதவியது. அந்த தோழர்களின் தொழில்நுட்ப சேவையும் வாடிக்கையாளர் சேவையும் மிகவும் சிறப்பு வாய்ந்தது.",
    comment2: "நுவரெலியாவிலிருந்து கொழும்புக்கு மரக்கறிகளை எடுத்துச் செல்வதற்கு பிரவாஹனாவின் பங்களிப்பை நாம் பாராட்ட வேண்டும். வாரம் ஒருமுறைதான் காய்கறிகள் விற்று வந்த நான், இப்போது வாரத்திற்கு மூன்று முறை அனுப்பும் முறையை உருவாக்கியுள்ளதால் வருமானம் கூடியுள்ளது.",
    comment3: "நான் கொழும்பில் உள்ள மன்னிங் மார்க்கெட்டில் இருந்து நுவரெலியாவுக்குத் திரும்பிக் கொண்டிருந்தபோது, ​​இவ்வளவு நேரம் காலியாகச் சென்றேன். ஆனால் பிரவாஹானாவுடன் நாள் கூலி ஓடத் தொடங்கியதிலிருந்து, நான் திரும்பியபோதும் கூலிகளைக் கண்டுபிடிக்க முடிந்தது. எனது வருமானம் அதிகரித்தது.",
    name1: "அனுரங்க சேனாநாயக்க",
    name2: "சுசித் சோமரத்ன",
    name3: "ருவன் ரத்நாயக்க",
    post1: "மொத்த விற்பனை - கிரிபத்கொட",
    post2: "காய்கறி விவசாயிகள் சங்கம் - நுவரெலியா",
    post3: "சாரதிகள் சங்கம் - கண்டி எலியா",
    whtTheySay: "அவர்கள் எங்களைப் பற்றி என்ன சொல்கிறார்கள்?",
    yourThoughts: "எங்களைப் பற்றி உங்கள் எண்ணங்கள் என்ன",
}

export default tamil;