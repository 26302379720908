import {useTranslation} from "react-i18next";
import {useState} from "react";

const LangDropDown = () => {
    const {i18n} = useTranslation();
    const {lang, setLang} = useState();
    const changeLanguageHandler = (language) => {
        i18n.changeLanguage(language); // Change the language dynamically
    };

    return (
        <select className="bg-gray-100 border border-black-700 rounded py-1 px-4" value={lang} onChange={e => changeLanguageHandler(e.target.value)}>
            <option value="en">English</option>
            <option value="sin">සිංහල</option>
            <option value="tml">தமிழ்</option>
        </select>
    )
}

export default LangDropDown;