import {useTranslation} from "react-i18next";
import React, {useRef} from "react";
import logo from "../assets/logo.svg";
import playstore from "../assets/playstore.png";
import applestore from "../assets/applestore.png";
import huawei from "../assets/huawei.png";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faSquareFacebook, faLinkedin, faSquareInstagram} from "@fortawesome/free-brands-svg-icons";
import emailjs from "@emailjs/browser";



const Footer = () => {
    const {t} = useTranslation();
    const currentYear = new Date().getFullYear();
    const form = useRef();


    const onSubmit = () => {

    }

    const sendEmail = (e) => {
        e.preventDefault();

        emailjs
            .sendForm(
                "service_prawahana",
                "template_5wj17ci",
                form.current,
                "a9dyDJjkPBpZ-pf_5"
            )
            .then(
                (result) => {
                    console.log(result.text);
                    console.log("messge sent");
                    alert("You are subscribed to the Prwawahana Transports!");
                },
                (error) => {
                    console.log(error.text);
                }
            );
    };

    return (
        <section className="flex flex-col text-prw-blue shadow-lg mt-40">
            <div className="flex flex-col items-center text-center py-7">
                <div className="text-3xl pb-4">
                    {t("stayTuned")}
                </div>
                <div className="text-md">
                    {t("enterEmail")}
                </div>
                <div>
                    <form ref={form} onSubmit={sendEmail}>
                        <div className="items-center inline-flex hidden-on-mobile">
                            <div><input className="rounded-l-lg border-2 border-prw-blue py-1.5 my-3" type="email" name="email"
                                        ></input></div>
                            <div><button className="bg-prw-blue hover:bg-blue-700 text-white py-2 px-4 rounded-r-lg" type='submit'>{t("subscribe")}</button></div>
                        </div>
                        <div className="items-center flex flex-col hidden-on-desktop">
                            <div><input className="rounded-lg border-2 border-prw-blue py-1.5 px-5" type="email" name="email"
                                        ></input></div>
                            <div><button className="bg-prw-blue hover:bg-blue-700 text-white py-2 px-4 rounded-lg mt-3" type='submit'>{t("subscribe")}</button></div>
                        </div>
                    </form>
                </div>
            </div>
            <div className="flex md:flex-row flex-col md:justify-between items-center border-t-2 border-gray-200 py-7 mx-10">
                <div className="md:w-1/3 w-full">
                    <div className="md:w-1/2 w-full md:text-left text-center">
                        <div><img className="md:w-8/12 w-full" src={logo} alt="Prawahana"/></div>
                        <div className="md:text-xs text-sm md:mx-0 mx-3">{t("tagLine")}</div>
                    </div>
                </div>
                <div className="w-1/3 text-center hidden-on-mobile">
                    {t("comeSoon")}
                    <div className="flex flex-row justify-center mt-2">
                        <div className="px-2"><img src={playstore} alt="playstore"/></div>
                        <div><img src={applestore} alt="appleStore"/></div>
                        <div className="px-2"><img src={huawei} alt="huawei"/></div>
                    </div>
                </div>
                <div className="w-1/3 md:text-right text-center md:justify-end md:mt-0 mt-5">
                    {t("findUs")}
                    <div className="flex flex-row justify-end mt-2">
                        <div className="px-2"><a href='https://www.facebook.com/Prawahana.LK' target="_blank"><FontAwesomeIcon icon={faSquareFacebook} size="2x" className="text-prw-blue"/></a></div>
                        <div><a href='https://www.linkedin.com/company/prawahana/' target="_blank"><FontAwesomeIcon icon={faLinkedin} size="2x" className="text-prw-blue"/></a></div>
                        <div className="pl-2"><a href='https://www.instagram.com/prawahana_lk' target="_blank"><FontAwesomeIcon icon={faSquareInstagram} size="2x" className="text-prw-blue"/></a></div>
                    </div>
                </div>
                <div className="w-full text-center border-t-2 border-gray-200 hidden-on-desktop pt-7 mt-5">
                    {t("comeSoon")}
                    <div className="flex flex-row justify-center mt-2">
                        <div className="px-2"><img src={playstore} alt="playstore"/></div>
                        <div><img src={applestore} alt="appleStore"/></div>
                        <div className="px-2"><img src={huawei} alt="huawei"/></div>
                    </div>
                </div>
            </div>
            <div className="flex flex-col text-center border-t-2 border-gray-200 py-7 mx-10">
                © {currentYear} | Prawahana PVT Ltd.<br/>
                All Rights Reserved.
            </div>
        </section>
    )
}

export default Footer;