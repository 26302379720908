import SingleTesti from "./SingleTesti";
import {Swiper, SwiperSlide} from "swiper/react";
import {A11y, Autoplay, Pagination} from "swiper/modules";
import {useTranslation} from "react-i18next";

const Testimonials = () => {
    const {t} = useTranslation()
    const comments = [
        {
            name: 'name1',
            post: 'post1',
            comment: 'comment1'
        },
        {
            name: 'name2',
            post: 'post2',
            comment: 'comment2'
        },
        {
            name: 'name3',
            post: 'post3',
            comment: 'comment3'
        }

    ];

    return (
        <section className="bg-prw-red py-10 overflow-hidden">
            <div className="flex flex-col mx-10 mb-8">
                <div className="text-lg text-prw-yellow">{t('whtTheySay')}</div>
                <div className="text-3xl text-white">{t('yourThoughts')}</div>
            </div>
            <div className="mx-10">
                <Swiper
                    modules={[Autoplay]}
                    autoplay={{
                        delay: 2000,
                        disableOnInteraction: false,
                    }}
                    loop={true}
                    spaceBetween={30}
                    breakpoints={{
                        480: {
                            slidesPerView: 1,
                            spaceBetween: 5,
                        },
                        768: {
                            slidesPerView: 2,
                            spaceBetween: 40,
                        },
                        1024: {
                            slidesPerView: 3,
                            spaceBetween: 30,
                        }}}
                 className="w-full">
                    {comments.map((comm, index) => (
                        <SwiperSlide key={index}>
                            <SingleTesti name={t(comm.name)} comment={t(comm.comment)} post={t(comm.post)} />
                        </SwiperSlide>
                    ))}
                </Swiper>
            </div>
        </section>
    );
}

export default Testimonials;