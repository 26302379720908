import {useEffect, useState} from "react";
import Driver from '../assets/Driver.svg';
import Shipper from '../assets/Shipper.svg';
import {useTranslation} from "react-i18next";
import HowWeWorkDriver from '../assets/howWeWorkDriver.png';
import HowWeWorkShipper from '../assets/howWeWorkShipper.png';

const HowWeWork = () => {
    const [userType, setUserType] = useState(true);
    const {t} = useTranslation();

    return (
        <section id="HowWeWork" className="flex flex-col mt-32 md:mt-32 mx-10">
            <div>
                <div className="text-lg  mb-2">How We Work?</div>
                <div className="inline-flex">
                    <div onClick={() => setUserType(true)}
                         className={`border-red-600 border-2 text-gray-800 font-bold px-4 rounded-l-lg ${userType ? "bg-prw-red text-white shadow-inset-2xl" : "bg-white"}`}>
                        <div className="inline-flex"><img className="mr-2" src={Driver} alt="D"/> <span>{t('driver')}</span></div>
                    </div>
                    <div onClick={() => setUserType(false)}
                         className={`border-red-600 border-2 text-gray-800 font-bold px-4 rounded-r-lg ${!userType ? "bg-prw-red text-white shadow-inset-2xl" : "bg-white"}`}>
                        <div className="inline-flex"><img className="mr-2" src={Shipper} alt="S"/> <span>{t('shipper')}</span></div>
                    </div>
                </div>
            </div>
            {
                userType ? (
                    <>
                        <div className="text-3xl text-prw-red  mt-2">
                            {t('workDriverTitle')}
                        </div>
                        <div className="w-full flex lg:flex-row flex-col lg:justify-between items-center lg:pb-20 lg:px-24 ">
                            <div className="hidden lg:inline w-full lg:w-5/12 my-2">
                                <img alt="SEN Transport" src={HowWeWorkDriver} width="1000" height="100%" className="rounded-lg"/>
                            </div>
                            <div className="lg:hidden w-full ">
                                <img alt="SEN Transport" src={HowWeWorkDriver} width="900" height="100%" className=""/>
                            </div>
                            <div className="flex flex-col w-full lg:w-6/12 my-20 px-6">
                                <div className="inline-flex items-center">
                                    <div className="bg-prw-blue text-white text-center font-bold rounded p-3 mr-4 my-2 w-12">01</div>
                                    <div className="text-prw-blue md:font-bold md:text-lg text-sm">{t('driverStep1')}</div>
                                </div>
                                <div className="inline-flex items-center">
                                    <div className="bg-prw-blue text-white text-center  font-bold rounded p-3 mr-4 my-2 w-12">02</div>
                                    <div className="text-prw-blue md:font-bold md:text-lg text-sm">{t('driverStep2')}</div>
                                </div>
                                <div className="inline-flex items-center">
                                    <div className="bg-prw-blue text-white text-center  font-bold rounded p-3 mr-4 my-2 w-12">03</div>
                                    <div className="text-prw-blue md:font-bold md:text-lg text-sm">{t('driverStep3')}</div>
                                </div>
                                <div className="inline-flex items-center">
                                    <div className="bg-prw-blue text-white text-center  font-bold rounded p-3 mr-4 my-2 w-12">04</div>
                                    <div className="text-prw-blue md:font-bold md:text-lg text-sm">{t('driverStep4')}</div>
                                </div>
                                <div className="inline-flex items-center">
                                    <div className="bg-prw-blue text-white text-center  font-bold rounded p-3 mr-4 my-2 w-12">05</div>
                                    <div className="text-prw-blue md:font-bold md:text-lg text-sm">{t('driverStep5')}</div>
                                </div>
                                <div className="inline-flex items-center">
                                    <div className="bg-prw-blue text-white text-center  font-bold rounded p-3 mr-4 my-2 w-12">06</div>
                                    <div className="text-prw-blue md:font-bold md:text-lg text-sm">{t('driverStep6')}</div>
                                </div>
                                <div className="inline-flex items-center">
                                    <div className="bg-prw-blue text-white text-center  font-bold rounded p-3 mr-4 my-2 w-12">07</div>
                                    <div className="text-prw-blue  md:font-bold md:text-lg text-sm">{t('driverStep7')}</div>
                                </div>
                            </div>
                        </div>
                    </>

                    ) :
                    (
                        <>
                            <div className="text-3xl text-prw-red  mt-2">
                                {t('workShipperTitle')}
                            </div>
                            <div className="w-full flex lg:flex-row flex-col lg:justify-between items-center lg:pb-20 lg:px-24 ">
                                <div className="lg:hidden w-full ">
                                    <img alt="SEN Transport" src={HowWeWorkShipper} width="800" height="100%" className=""/>
                                </div>
                                <div className="flex flex-col w-full lg:w-6/12 my-20 px-6">
                                    <div className="inline-flex items-center">
                                        <div className="bg-prw-blue text-white text-center  font-bold rounded p-3 mr-4 my-2 w-12">01</div>
                                        <div className="text-prw-blue md:font-bold md:text-lg text-sm">{t('shipperStep1')}</div>
                                    </div>
                                    <div className="inline-flex items-center">
                                        <div className="bg-prw-blue text-white text-center  font-bold rounded p-3 mr-4 my-2 w-12">02</div>
                                        <div className="text-prw-blue md:font-bold md:text-lg text-sm">{t('shipperStep2')}</div>
                                    </div>
                                    <div className="inline-flex items-center">
                                        <div className="bg-prw-blue text-white text-center  font-bold rounded p-3 mr-4 my-2 w-12">03</div>
                                        <div className="text-prw-blue md:font-bold md:text-lg text-sm">{t('shipperStep3')}</div>
                                    </div>
                                    <div className="inline-flex items-center">
                                        <div className="bg-prw-blue text-white text-center  font-bold rounded p-3 mr-4 my-2 w-12">04</div>
                                        <div className="text-prw-blue  md:font-bold md:text-lg text-sm">{t('shipperStep4')}</div>
                                    </div>
                                    <div className="inline-flex items-center">
                                        <div className="bg-prw-blue text-white text-center  font-bold rounded p-3 mr-4 my-2 w-12">05</div>
                                        <div className="text-prw-blue md:font-bold md:text-lg text-sm">{t('shipperStep5')}</div>
                                    </div>
                                    <div className="inline-flex items-center">
                                        <div className="bg-prw-blue text-white text-center  font-bold rounded p-3 mr-4 my-2 w-12">06</div>
                                        <div className="text-prw-blue md:font-bold md:text-lg text-sm">{t('shipperStep6')}</div>
                                    </div>
                                    <div className="inline-flex items-center">
                                        <div className="bg-prw-blue text-white text-center  font-bold rounded p-3 mr-4 my-2 w-12">07</div>
                                        <div className="text-prw-blue md:font-bold md:text-lg text-sm">{t('shipperStep7')}</div>
                                    </div>
                                </div>
                                <div className="hidden lg:inline w-full lg:w-5/12 my-20">
                                    <img alt="SEN Transport" src={HowWeWorkShipper} width="800" height="100%" className="rounded-lg"/>
                                </div>
                            </div>
                        </>
                    )
            }
        </section>
    )
}

export default HowWeWork;