const sinhala = {
    prawahana: "ප්‍රවාහන",
    whtWeDo: "අපේ සේවය ගැන",
    footer: "This is Footer Sinhala",
    heroTxtSmall: "ශ්‍රීලාංකීය සැපයුම් ප්‍රවාහනයේ",
    heroTxtLarge: "දැවැන්තම තාක්ෂණික සන්නාමය",
    heroButton: "ළඟදීම බලාපොරොත්තු වන්න",
    comeSoon: "ළඟදීම බලාපොරොත්තු වන්න",
    findUs: "අපිව සොයන්න",
    tagLine: "ශ්‍රීලංකා සැපයුම් ප්‍රවාහන ක්ෂේත්‍රයේ දැවැන්තම තාක්ෂණික සන්නාමය",
    stayTuned: "රැඳී සිටින්න අප සමඟ!",
    enterEmail: "ඔබගේ විද්‍යුත් තැපෑල මෙහි ඇතුලත් කරන්න",
    subscribe: "Subscribe",
    whoWeAre: "අපේ දේ අගයන ප්‍රවාහන අපි කවුද ?",
    whoWeAreDesc: "\"ප්‍රවාහන\" සන්නාමය ශ්‍රීලාංකික ප්‍රජාවගේ අවශ්‍යතාවන්ට ගැලපෙන අයුරින්ම සැකසුනු විප්ලවීය තාක්ෂණික මොඩලයක්. ප්‍රවාහන සැපයුම් ක්ෂේත්‍රයේ පවත්නා   බොහෝ ගැටළු හඳුනාගෙන, ඒවාට නිවැරදි පිළියම් සොයමින් ගොඩනැඟුනු \"ප්‍රවාහන\" සන්නාමය, ගොවි මහතුන්ටත්, තොග බදු වෙළඳ ප්‍රජාවටත් , රියදුරු මහතුන්ටත් වාසි රැසක්, ආදායම් මාර්ග වැඩිදියුණු කරවීම් රැසක් ලබා දෙන ඉතා කදිම මාර්ගපොදෙශි ව්‍යාපෘතියක්. ඕස්ට්‍රේලියාවේ දැනට සාර්ථකව ක්‍රියාත්මක වන Sen Transport නැමැති සන්නාමයට අනුරූපව නිර්මාණය කල මේ \"ප්‍රවාහන\" තාක්ෂණික මොඩලය , සේවාවන් රැසක් සමඟ ඔබ හමුවට පැමිණේවි. ප්‍රවාහන සැපයුම් ක්ෂේත්‍රයේ සිටිනා සෑම ප්‍රජාවකටම ආදායම මාර්ග පුළුල් කරන \"ප්‍රවාහන\" සන්නාමය හෙට, දවසේ ශ්‍රීලංකාව ආර්ථික වශයෙන් දියුණුවකට ලක් කිරීම අප කණ්ඩායමේ මුලික අරමුණයි.",
    appriBrand: "අපේ දේ අගයන ඔබ වෙනුවෙන්ම",
    driver: "රියදුරු",
    shipper: "සැපයුම්කරු",
    workDriverTitle: "“ප්‍රවාහන” සමඟින් ඉතා කෙටිකාලයකින් හයර් සොයාගෙන ඒවා හසුරුවන්නේ කෙසේද?",
    workShipperTitle: "“ප්‍රවාහන” සමඟින් ඉතා කෙටි කාලයකින් ඔබගේ සැපයුම් සියල්ල ප්‍රවාහනය කරගන්නේ කෙසේද?",
    driverStep1: " “ප්‍රවාහන”  App එක බාගත කර විවෘත කරන්න",
    driverStep2: "පුද්ගලික තොරතුරු ඇතුළත් කිරීමෙන්, අදාළ ලේඛන උඩුගත කිරීමෙන් ගිණුම ලියාපදිංචි කරන්න",
    driverStep3: "ඔබගේ ඔන්ලයින් බොත්තම ක්‍රියාත්මක කර හයර් සොයන්න",
    driverStep4: "හයර් එකක් තෝරා ඔබේ ලංසු අගය ඇතුලත් කරන්න",
    driverStep5: "සාකච්ඡා කර අවසාන ලංසු අගය පිළිගන්න",
    driverStep6: "හයර් එක ආරම්භ කර හයර් එකේ නවතම තත්ත්වය යාවත්කාලීන කරන්න",
    driverStep7: "හයර් එක සම්පූර්ණ කර මුදල් ආපසු ගන්න",
    shipperStep1: " “ප්‍රවාහන”  App එක බාගත කර විවෘත කරන්න",
    shipperStep2: "ලියාපදිංචි වී ඔබගේ ගිණුම සකසන්න",
    shipperStep3: "ඔබගේ ඩිලිවරි එකේ විස්තර පළ කර ලංසු ලබා ගන්න",
    shipperStep4: "සාකච්ඡා කර හයර් එක ලබා දෙන්න",
    shipperStep5: "ගෙවීම් කරන්න සහ හයර්  එකේ නවතම තත්ත්වය නිරීක්ෂණය කරන්න",
    shipperStep6: "Pick-up සහ Proof Of Delivery බලන්න",
    shipperStep7: "සම්පූර්ණ කිරීමේ දැනුම්දීම සහ තෘප්තිමත් පෝරමය යාවත්කාලීන කරන්න",
    comment1: "ප්‍රවාහන කියන්නේ හරිම ප්‍රයෝජනවත් සන්නාමයක්. මගේ වෙළඳාම වැඩි දියුණු කර ගන්න ප්‍රවාහන කණ්ඩායම සෑහෙන්න උදව් කලා. ඒ ගොල්ලන්ගේ තාක්ෂණික සේවය, පාරිභෝගික සේවය හරිම සුවිශේෂියයි.",
    comment2: "නුවර එළියෙන් එළවලු , කොළඹට ගෙනයන්න ප්‍රවාහන දුන්නු දායකත්වය අගය කරන්නම ඕනේ. ඉස්සර සතියට එකපාරක් පමණක් එළවලු තොග විකුනපු මට අද සතියට තුන් පාරක් යවන්න ක්‍රමයක් හදල දීපු එකෙන් මගේ ආදායම තවත් වැඩිවුනා.",
    comment3: "එළවලු හයර් නුවර එළියේ ඉඳන් කොළඹ මැනින් මාකට් එකට ගෙනත් ආයිත් යනකොට හිස්ව තමයි මම මෙච්චර කාලයක් හයර් ගියේ, ඒත් ප්‍රවාහන එක්ක හයර් දුවන්න පටන් ගත්ත දවසේ ඉඳන් එනකොටත් හයර් හොයා ගන්න පුළුවන් වුනා. මගේ ආදායම වැඩිවුනා. ",
    name1: "අනුරංග සේනානායක",
    name2: "සුචිත් සෝමරත්න",
    name3: "රුවන්  රත්නායක",
    post1: "තොග වෙළඳාම් - කිරිබත්ගොඩ",
    post2: "එළවලු ගොවි සංගමය  - නුවර එළිය",
    post3: "රියදුරු සංගමය   - නුවර එළිය",
    whtTheySay: "ඔවුන් අපි ගැන පවසන්නේ කුමක්ද?",
    yourThoughts: "ප්‍රවාහන අපි ගැන සහෘද ඔබගේ අදහස්",
}

export default sinhala;