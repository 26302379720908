import {Swiper, SwiperSlide} from "swiper/react";
import {Autoplay} from "swiper/modules";
import gallery1 from '../assets/gallery1.jpg';
import gallery2 from '../assets/gallery2.jpg';
import gallery3 from '../assets/gallery3.jpg';
import gallery4 from '../assets/gallery4.jpg';
import gallery5 from '../assets/gallery5.jpg';
import gallery6 from '../assets/gallery6.jpg';

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';

const ImageGallery = () => {

    const gallery = [
        gallery1,
        gallery2,
        gallery3,
        gallery4,
        gallery5,
        gallery6,
    ]

    return (
        <div className="flex flex-row items-center justify-center w-auto my-10">
            <Swiper
                modules={[Autoplay]}
                autoplay={{
                    delay: 2000,
                    disableOnInteraction: false,
                }}
                loop={true}
                spaceBetween={5}
                breakpoints={{
                    480: {
                        slidesPerView: 1,
                        spaceBetween: 5,
                    },
                    768: {
                        slidesPerView: 2,
                        spaceBetween: 40,
                    },
                    1024: {
                        slidesPerView: 4,
                        spaceBetween: 5,
                    }}}
            className="justify-items-center"
            >
                {gallery.map((image, index) => (
                    <SwiperSlide key={index}>
                        <img className="h-9/12" src={image} alt={'images'}/>
                    </SwiperSlide>
                ))}
            </Swiper>
        </div>
    );
}

export default ImageGallery;