import {useTranslation} from "react-i18next";

const WhoWeAre = () => {
const {t} = useTranslation();

    return (
        <section className="flex flex-col items-center my-40">
            <div className="text-prw-red text-3xl text-center mb-5">
                {t('whoWeAre')}
            </div>
            <div className="text-center mx-10 md:text-lg text-prw-blue">
                {t("whoWeAreDesc")}
            </div>
        </section>
    );
}

export default WhoWeAre;