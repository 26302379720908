import {useTranslation} from "react-i18next";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faAnglesDown} from "@fortawesome/free-solid-svg-icons";
import React from "react";
import playstore from "../assets/playstore.png";
import applestore from "../assets/applestore.png";
import huawei from "../assets/huawei.png";
import header1 from "../assets/header_1.jpg";
import header2 from "../assets/header_2.jpg";
import header3 from "../assets/header_3.jpg";
import {Autoplay} from "swiper/modules";
import {Swiper, SwiperSlide} from "swiper/react";

const images = [
    header1, header2, header3
];

const Hero = () => {
    const {t} = useTranslation();


    return (
        <>
            <div className="relative h-screen w-full">
        <div className="absolute w-full h-screen" style={{ zIndex: 20 }}>
            <Swiper
                modules={[Autoplay]}
                autoplay={{
                    delay: 2000,
                    disableOnInteraction: false,
                }}
                loop={true}
                effect="fade"
                className="h-full"
            >
                {images.map((image, index) => (
                    <SwiperSlide key={index}>
                        <div className="relative w-full h-full">
                            <img
                                alt={`HeroImg${index}`}
                                src={image}
                                className="absolute w-full h-full object-cover"
                            />
                            <div className="absolute h-full w-full bg-gradient-to-r  from-black"/>
                        </div>
                    </SwiperSlide>
                ))}
            </Swiper>

            <div className="absolute w-full h-full top-0 flex flex-col justify-center items-start z-20">
                <div className="flex flex-col mt-38 md:mx-0 pt-1 md:mt-62 text-center md:text-left px-6 md:px-20">
                    <div className="md:text-4xl text-2xl text-white my-1 sm:text-3xl mx-20">
                        {t('heroTxtSmall')}
                    </div>
                    <div className="md:text-6xl text-4xl text-white my-1 sm:text-6xl mx-20">
                        {t('heroTxtLarge')}
                    </div>
                    <div className="text-2xl md:text-3xl py-10 mx-20">
                        <button className="bg-red-600 hover:bg-red-700 text-white py-2 px-4 rounded">
                            {t('heroButton')}
                        </button>
                    </div>
                </div>
            </div>
        </div>


    <div className="flex md:flex-row flex-col absolute bg-white shadow-bottom w-full mt-screen items-center justify-between py-2">
        <div className="md:text-left text-center md:w-1/3 w-full text-xl text-prw-red pl-5">{t('appriBrand')}</div>
        <div className='w-1/3 text-center text-3xl hidden-on-mobile'><a href='#HowWeWork'><FontAwesomeIcon icon={faAnglesDown} beatFade /></a></div>
        <div className="md:text-right text-center md:w-1/3 w-full text-prw-blue md:pr-5">
            {t("comeSoon")}
            <div className="flex flex-row md:justify-end justify-center mt-2">
                <div className="px-2"><img src={playstore} alt="playstore"/></div>
                <div><img src={applestore} alt="appleStore"/></div>
                <div className="pl-2"><img src={huawei} alt="huawei"/></div>
            </div>
        </div>
    </div>
            </div>
</>
    );
}

export default Hero;