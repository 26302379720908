const SingleTesti = (props) => {
    return (
        <div className="bg-white shadow rounded-xl m-15 w-full items-center justify-center py-5">
            <ul className="flex justify-center my-5">
                <li>
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 96 960 960"
                        className="w-5 text-primary dark:text-primary-400">
                        <path
                            fill="gold"
                            d="m233 976 65-281L80 506l288-25 112-265 112 265 288 25-218 189 65 281-247-149-247 149Z"
                        />
                    </svg>
                </li>
                <li>
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 96 960 960"
                        className="w-5 text-primary dark:text-primary-400">
                        <path
                            fill="gold"
                            d="m233 976 65-281L80 506l288-25 112-265 112 265 288 25-218 189 65 281-247-149-247 149Z"
                        />
                    </svg>
                </li>
                <li>
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 96 960 960"
                        className="w-5 text-primary dark:text-primary-400">
                        <path
                            fill="gold"
                            d="m233 976 65-281L80 506l288-25 112-265 112 265 288 25-218 189 65 281-247-149-247 149Z"
                        />
                    </svg>
                </li>
                <li>
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 96 960 960"
                        className="w-5 text-primary dark:text-primary-400">
                        <path
                            fill="gold"
                            d="m233 976 65-281L80 506l288-25 112-265 112 265 288 25-218 189 65 281-247-149-247 149Z"
                        />
                    </svg>
                </li>
                <li>
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 96 960 960"
                        className="w-5 text-primary dark:text-primary-400">
                        <path
                            fill="gold"
                            d="m233 976 65-281L80 506l288-25 112-265 112 265 288 25-218 189 65 281-247-149-247 149Z"
                        />
                    </svg>
                </li>
            </ul>
            <div className="text-center italic border-b-2 border-gray-300 mx-5 mb-5 md:text-md text-sm" style={{height:'170px'}}>"{props.comment}"</div>
            <div className="text-center font-bold mt-8">{props.name}</div>
            <div className="text-center text-xs">{props.post}</div>
        </div>
    );
}

export default SingleTesti;